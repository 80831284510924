<template>
  <div class="flix-form-group">
    <label class="flix-label" v-if="label" :for="id"
      >{{ label }} <help class="flix-help" v-if="help">{{ help }}</help></label
    >
    <select
      class="flix-form-control"
      :class="{ 'flix-border-danger': error }"
      v-model="value"
      :id="id"
      @change="setValue"
      :style="styling.input"
    >
      <option
        v-for="(v, index) in settings.values"
        :key="index"
        :value="index"
        >{{ v }}</option
      >
    </select>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    settings: Object,
    data: [String, Number],
    label: String,
    callback: Function,
    placeholder: String,
    error: [Boolean, String],
    styling: [Boolean, Object],
    help: String
  },
  data() {
    return {
      check: false,
      id: new Date().getTime() + Math.random(),
      value: this.getValue()
    };
  },
  methods: {
    setValue() {
      this.callback(this.value);
    },
    getValue() {
      if (!this.settings.value) {
        return this.settings.default;
      }
      return this.settings.value;
    }
  }
};
</script>
<style lang="sass" scoped></style>
